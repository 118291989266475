/* src/components/Header.css */
.faq-page .header {
  position: relative;
  width: 100%;
  height: 350px; /* Ajuste la hauteur selon tes besoins */
  overflow: hidden;
  background-color: #e74c3c;
}

.faq-page .clip-path {
  clip-path: polygon(0 0, 100% 0, 100% 89%, 0% 100%);
}

.faq-page .header-image-container {
  width: 100%;
  height: 100%;
}

.faq-page .header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.faq-page .header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(204, 70%, 53%, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.faq-page .header-title {
  color: white;
  font-size: 36px;
}
