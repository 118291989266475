@import '/node_modules/primeflex/primeflex.css';
@import 'primeicons/primeicons.css';
        
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat';
}

.text-primary {
  color: #3498db !important;
}

.text-secondary {
  color: #e74c3c !important;
}

.bg-primary {
  background-color: #3498db !important;
}

.bg-primary-hover:hover {
  background-color: rgb(52, 152, 219, 0.7) !important;
}

.bg-tertiary {
  background-color: #153860 !important;
}

.border-color-primary {
  border-color: #3498db !important;
}

.max-width-container {
  max-width: 1300px;
}

.page {
  flex: 1;
}

input, select, label {
  font-family: 'Montserrat' !important;
}

:root {
  --clr-primary: #3498db;
  --white: #ffffff;
}