  .site-footer {
      background-color: var(--secondary-clr);
      /* background-color: #3498db; */
      color: #ecf0f1;
      padding: 1rem 0 1rem;
      font-family: 'Arial', sans-serif;
  }

  .footer-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 2rem;
  }

  .footer-content>div {
      flex: 1;
      min-width: 250px;
      /* margin-bottom: 2rem; */
  }

  .footer-content h3 {
      color: #3498db;
      font-size: 1.2rem;
      margin-bottom: 1rem;
      position: relative;
      padding-bottom: 0.5rem;
  }

  /* .footer-content h3::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 50px;
      height: 2px;
      background-color: #3498db;
  } */

  .contact-info address {
      font-style: normal;
  }

  .contact-info p {
      margin: 0.5rem 0;
      display: flex;
      align-items: center;
  }

  .contact-info i {
      margin-right: 0.5rem;
      color: #3498db;
  }

  .social-links {
      display: flex;
      flex-direction: column;
  }

  .social-icon {
      color: #ecf0f1;
      font-size: 1.5rem;
      margin-right: 1rem;
      transition: color 0.3s ease;
  }

  .social-icon i{
      /* font-size: 1.5rem; */
  }

  .social-icon:hover {
      color: #3498db;
  }

  .quick-links ul {
      list-style: none;
      padding: 0;
  }

  .quick-links li {
      margin-bottom: 0.5rem;
  }

  .quick-links a {
      color: #ecf0f1;
      text-decoration: none;
      transition: color 0.3s ease;
  }

  .quick-links a:hover {
      color: #3498db;
  }

  .footer-bottom {
      text-align: center;
      margin-top: 2rem;
      padding-top: 1rem;
      border-top: 1px solid #34495e;
  }

  @media (max-width: 768px) {
      .footer-content {
          flex-direction: column;
      }

      .footer-content>div {
          margin-bottom: 2rem;
      }
      .footer-content>.quick-links {
          margin-bottom: 0;
      }
  }