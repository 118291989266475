.faq {
  margin: 20px;

}

.select-box {
  padding: 10px;
  background-color: #f2f2f2;
  border: 1px solid #ccc;
}

.select-box span {
  color: #3498db;
}

.faq-themes {
  text-align: left;
}

.faq-theme h2 {
  color: #e74c3c;
}

.line {
  border-bottom: 1px solid #e74c3c;
}

.faq-theme ul {
  list-style: none;
  padding: 0;
}

.faq-theme ul li {
  margin: 5px 0;
}

.faq-theme ul li span {
  color: #3498db;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.faq-theme ul li img {
  margin-right: 10px;
  height: 14px;
}

.faq-question .arrow {
  color: red;
}

.p-accordion-tab {
  margin-bottom: 1rem;
}

.p-accordion-header a {
  background-color: transparent !important;
}

.p-accordion-header a .p-accordion-header-text {
  font-weight: 500;
  color: var(--tertiary-clr);
}

.p-accordion-header a svg {
  color: var(--primary-clr);
}

.p-accordion .p-accordion-header .p-accordion-header-link  {
  padding: 0;
  border: 0;
}


.p-accordion-content {
  border: 0;
  padding-bottom: .5rem;
}