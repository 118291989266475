.profil-message-page {
    flex: 1;
}

.profil-message-page .profil-message-container {
    flex: 1;
}


.profil-message-container .banner {
    background: url('/public/assets/images/trip.jpg') no-repeat center center;
    background-size: cover;
    min-height: 200px;
    position: relative;
}

.profil-message-container .banner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.profil-message-container .banner-content {
    position: relative;
    color: white;
    text-align: center;
}

.profil-message-container .banner-content h1 {
    font-size: 1.5em;
}
