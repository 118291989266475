.terms-and-conditions {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

.terms-page .header {
    background-color: #e74c3c;
}

.terms-page .toolbar {
    background: url('/public/assets/images/exchange5.webp') no-repeat center center;
    background-size: cover;
    height: 430px;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 89%, 0% 100%);
}

.terms-page .toolbar::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(52, 152, 219, 0.5);
}

.terms-page .toolbar-content {
    height: 100%;
    position: relative;
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

h2 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 15px;
}

h3 {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
}

p {
    margin-bottom: 15px;
}