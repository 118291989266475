.profil-message-page {
    
}
.profil-message-page .message-item-card p {
    display: -webkit-box;
    max-width: 627.93px;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@media (max-width: 500px) {
    .profil-message-page .message-item-card p {
    
    }
}