header {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.header .nav {
    /* box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08); */
}

.p-sidebar-mask,
.p-sidebar-visible {
    z-index: 80 !important;
}

.header .nav .App-logo {
    width: 100%;
    height: auto;
}

.header .nav .link a {
    text-decoration: none;
}

.header .nav .link {
    cursor: pointer;
    text-align: center !important;
}