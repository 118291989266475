
.p-tabmenu {
    width: 100% !important;
}

.p-inputnumber-input {
    width: 100% !important;
}

.anounce-card {
    background: #153860;
}

.anounce-card .p-tabmenu-nav {
    background-color: transparent;
    border: none;
    width: 100% !important;
    display: grid;
    grid-template-columns: 1fr 1fr;
}


.p-highlight a {
    /* background-color: #153860 !important; */
    background-color: #153860 !important;
}

#pr_id_2_1 a {
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0;
}

#pr_id_2_0 a {
    border-top-right-radius: 0;
    border-top-left-radius: 0.25rem;
}
#pr_id_8_1 a {
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0;
}

#pr_id_8_0 a {
    border-top-right-radius: 0;
    border-top-left-radius: 0.25rem;
}

.anounce-card .p-tabmenuitem a {
    font-weight: 500;
    color: white;
}

.tab-menu-container .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    background-color: #3498db;
    /* background-color: #153860; */
    border: none;
}

.text-white .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root{
    background-color: white !important;
}

.text-white .MuiFormLabel-filled{
    /* color: #3498db !important; */
}

.text-white .MuiFilledInput-root{
    background-color: white !important;
}

input {
    font-family: 'Montserrat' !important;
}

.dialog-tab-menu-container .p-highlight a {
    background-color: transparent !important;
}

.dialog-tab-menu-container .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link  {
    background-color: transparent !important;
}

.dialog-tab-menu-container .p-menuitem-text{
    font-size: .875rem;
}