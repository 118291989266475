.home-page .clip-path {
    clip-path: polygon(0 0, 100% 0, 100% 89%, 0% 100%);
}

.home-page .header {
    background-color: #e74c3c;
}

.home-page  .toolbar {
    background: url('/public/assets/images/exchange2.webp') no-repeat center center;
    background-size: cover;
    min-height:  510px;;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 89%, 0% 100%);
}


.home-page .toolbar .text-content {
    background: rgb(52, 152, 219, 0.5);
}

.home-page .toolbar .image-container .image-content {
    background-position-x: -229px !important;
    background-position-y: -664px !important;
    background: url('/public/assets/images/exchange2.webp') no-repeat center center;
    background-size: cover;
}

/* .home-page  .toolbar::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(52, 152, 219, 0.5);
} */

.home-page  .home-page .toolbar-content {
    height: 100%;
    position: relative;
    color: white;
    text-align: center;
}

/* .home-page .toolbar-content h1 span {
    background: white;
    color: #e74c3c;
    width: fit-content;
} */

.home-page .toolbar-content h1 span {
    color: #e74c3c;
}

.steps-container {
    background-color: #3498db;
    color: white;
    text-align: center;
}

.p-steps {
    z-index: 5;
    /* position: absolute; */
    margin-left: 1rem;
    margin-top: 1rem;
}

.p-steps-item {
    justify-content: flex-start;
}

.p-steps li::before {
    border-top: 2px solid #ffffff;
    position: absolute;
    margin-top: 0;
}

.p-steps li a {
    background-color: transparent !important;
}

.p-steps li a span {
    background: #fff;
    color: #000;
}

.steps-content .step img {
    width: 40px;
}

.steps-content .step p {
    margin-top: 10px;
    font-size: 1em;
}

.p-stepper-action {
    background-color: transparent !important;
}

.p-stepper-panels {
    background-color: var(--secondary-clr) !important;
}

.p-stepper .p-stepper-header:has(~ .p-highlight) .p-stepper-separator {
    background-color: var(--secondary-clr) !important;
}


.p-stepper-separator {
    background-color: var(--secondary-clr) !important;
}


.testimonial-card {
    background-color: rgb(52, 152, 219, 0.2);
}

@media (max-width: 500px) {
    .home-page .toolbar {
        clip-path: none !important;
    }

    .home-page .toolbar .image-container .image-content {
        background-position-x: -386px !important;
    }
}