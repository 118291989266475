.white-background .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root{
    background-color: white !important;
}
.white-background .MuiFormLabel-filled{
    /* color: #3498db !important; */
}

.css-953pxc-MuiInputBase-root-MuiInput-root::before{
    border: none !important;
    border-bottom: none !important;
}

.white-background .MuiFilledInput-root{
    background-color: white !important;
}

input {
    font-family: 'Montserrat' !important;
}

