.profil-page {}

.profil-page .grid {}

.text {
    font-size: 1.5rem;
    color: #153860;
    padding-block: 1.5rem;
    border-bottom: 1.5px solid #153860;
}

.announce-container h1 {
    color: #153860;
    padding-block: 1.5rem;
    border-bottom: 1.5px solid #153860;
}

.text-color {
    color: #153860;
}

.p-rating .p-rating-item-active svg {
    color: var(--yellow-400) !important;
}

.p-rating .p-rating-item .p-rating-icon {
    color: var(--yellow-400) !important;
}

.sidebar .link:hover {
    color: var(--clr-primary);
    cursor: pointer;
    font-weight: bold;
}

.sidebar .active {
    color: var(--clr-primary) !important;
}